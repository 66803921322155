import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Container } from 'react-bootstrap';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import usePromise from 'react-promise';
import { Link, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import social, { authenticatedEmployee } from '../../../apis/social';
import {
  calculateStat,
  checkForEmployeeWorkDay,
  formatDuration,
  sumTimeParts,
} from '../../../utility/time';
import './Calendar.css';
import ExtendedOverviewSection from './ExtendedOverviewSection';
import OverviewSection from './OverviewSection';
import 'react-calendar/dist/Calendar.css';
import {SickNoteCollection} from "../../../core/SickNoteCollection";
import {DateTime} from "luxon";
import {useObservable} from "react-use-observable";
import {Paginated} from "@feathersjs/feathers";
import {YearlySickNotesDB} from "../../../apis/attributes/yearly-sick-notes";

const css = `
    .calendar-blue-background {
        background-color: rgba(1, 43, 70, 0.04);  !important
    }
`;

export const Dashboard: React.FC<{ defaultDate?: string }> = ({
  defaultDate = new Date().toISOString(),
}) => {
  const { t } = useTranslation();

  // get it from the url from router
  const employeeId = useParams<{ employeeId: string }>().employeeId;

  // THIS IS THE DATE THAT IS SELECTED IN THE CALENDAR
  const [currentTargetDate, onDateChanged] = useState(moment(defaultDate).toDate());

  const { value: auth } = usePromise(authenticatedEmployee());

  const [allSickNotes] = useObservable<Paginated<YearlySickNotesDB> | null>(
      () =>
          social
              .service('yearly-sick-notes')
              .watch()
              .find({
                query: {
                  $sort: {
                    year: 1,
                  },
                  employeeId,
                },
              }),
      [employeeId],
  );

  let parsedSickNotes : SickNoteCollection[] | undefined = undefined;
  let calledInSick = false;
  const today = DateTime.fromJSDate(currentTargetDate);

  // check if sicknotes are in db, if so, parse
  if (allSickNotes?.data?.length) {
    parsedSickNotes = allSickNotes?.data?.map((sickNote) => SickNoteCollection.fromAttributes(sickNote));
    parsedSickNotes.filter((sickNote) => sickNote.year === currentTargetDate.getFullYear());
    // check if a sicknote exists for today, meaning that the employee is sick today
    for (const sickNoteCollection of parsedSickNotes) {
      for (const sickNote of sickNoteCollection.sickNotes) {
        if (sickNote.start.startOf('day') <= today && sickNote.end.endOf('day') >= today) {
          calledInSick = true;
          break;
        }
      }
    }
  }

  const [dayEmployeeStatistics, retryEmployment] = useAsyncFn(async () => {
    const res: any = await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: currentTargetDate.toISOString(),
      startDate: currentTargetDate.toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: false,
    });
    return res as any;
  }, [auth, (auth as any)?.user, currentTargetDate?.toString()]);

  const [weekEmployeeStatistics, retryWeekEmployment] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: moment(currentTargetDate).endOf('week').toISOString(),
      startDate: moment(currentTargetDate).startOf('week').toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: false,
    })) as any;
    return res as any;
  }, [auth, (auth as any)?.user, currentTargetDate?.toString()]);

  const [monthEmployeeStatistics, retryMonthEmployment] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: moment(currentTargetDate).endOf('month').toISOString(),
      startDate: moment(currentTargetDate).startOf('month').toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: false,
    })) as any;
    return res as any;
  }, [auth, (auth as any)?.user, currentTargetDate?.toString()]);

  const [yearEmployeeStatistics, retryYearEmployment] = useAsyncFn(async () => {
    const res: any = (await (social.service('employee') as any).patch(employeeId, {
      employeeId,
      endDate: moment(currentTargetDate).endOf('year').toISOString(),
      startDate: moment(currentTargetDate).startOf('year').toISOString(),
      statistics: true,
      buildMonthReport: false,
      buildYearReport: false,
    })) as any;
    return res as any;
  }, [auth, (auth as any)?.user, currentTargetDate?.toString()]);

  useEffect(() => {
    retryEmployment();
    retryWeekEmployment();
    retryMonthEmployment();
    retryYearEmployment();
  }, [auth, (auth as any)?.user, employeeId, currentTargetDate?.toString()]);

  return (
    <Container className='pb-5'>
      <style>{css}</style>

      <Calendar
        className='rounded px-3 py-1 mt-3 shadow background calendar-blue-background w-100'
        locale='de'
        // TODO CHECK THIS FOR TIME ZONE
        onChange={(d: any) => onDateChanged(moment(d).utc().add(2, 'hours').toDate())}
        value={currentTargetDate}
      />

      <p className='mt-5 mb-2'>
        {' '}
        <strong>
          {t('DAILY_OVERVIEW') as string}
          {' > '}
        </strong>{' '}
      </p>

      {dayEmployeeStatistics?.value && (
        <Link to={`/employee/${employeeId}/day?dd=${currentTargetDate.toISOString()}`}>
          <ExtendedOverviewSection
            labelLeft={t('TIME_WORKED') as string}
            labelLeftVal={calculateStat(
              dayEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
            )}
            labelRight={t('BALANCE') as string}
            labelRightVal={formatDuration(
              sumTimeParts(
                dayEmployeeStatistics?.value?.balance.careTimePart,
                dayEmployeeStatistics?.value?.balance.disposalTimeRegularPart,
                dayEmployeeStatistics?.value?.balance.disposalTimeIrregularPart,
              ),
            )}
            facilityDayType={
              dayEmployeeStatistics?.value?.dayStatusList?.length &&
              dayEmployeeStatistics?.value?.dayStatusList?.[0]?.facilityDayType
            }
            isWorkDay={checkForEmployeeWorkDay(dayEmployeeStatistics?.value?.dayStatusList)}
            isFilledOut={
              dayEmployeeStatistics?.value?.dayStatusList &&
              dayEmployeeStatistics?.value?.dayStatusList[0]?.isFilledOut
            }
            isSick={calledInSick}
          />
        </Link>
      )}
      <p className='mt-5 mb-2'>
        {' '}
        <strong>
          {t('WEEKLY_OVERVIEW') as string}
          {' > '}
        </strong>{' '}
      </p>

      {weekEmployeeStatistics && (
        <Link to={`/employee/${employeeId}/week?dd=${currentTargetDate.toISOString()}`}>
          <OverviewSection
            labelLeft={t('TIME_WORKED') as string}
            labelLeftVal={calculateStat(
              weekEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
            )}
            labelRight={t('BALANCE') as string}
            labelRightVal={calculateStat(weekEmployeeStatistics?.value?.balance)}
          />
        </Link>
      )}

      <p className='mt-5 mb-2'>
        {' '}
        <strong>
          {t('MONTHLY_OVERVIEW') as string}
          {' > '}
        </strong>{' '}
      </p>
      {monthEmployeeStatistics && (
        <Link to={`/employee/${employeeId}/month?dd=${currentTargetDate.toISOString()}`}>
          <OverviewSection
            labelLeft={t('TIME_WORKED') as string}
            labelLeftVal={calculateStat(
              monthEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
            )}
            labelRight={t('BALANCE') as string}
            labelRightVal={calculateStat(monthEmployeeStatistics?.value?.balance)}
          />{' '}
        </Link>
      )}

      <p className='mt-5 mb-2'>
        {' '}
        <strong>
          {t('YEARLY_OVERVIEW') as string}
          {' > '}
        </strong>{' '}
      </p>
      {yearEmployeeStatistics && (
        <Link to={`/employee/${employeeId}/year?dd=${currentTargetDate.toISOString()}`}>
          <OverviewSection
            labelLeft={t('TIME_WORKED') as string}
            labelLeftVal={calculateStat(
              yearEmployeeStatistics?.value?.actualTime?.actualTimeAccepted,
            )}
            labelRight={t('BALANCE') as string}
            labelRightVal={calculateStat(yearEmployeeStatistics?.value?.balance)}
          />{' '}
        </Link>
      )}
    </Container>
  );
};

export default Dashboard;
